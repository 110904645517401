import React from 'react'
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import Product from '../../Products';
const Products = () => {
  return (
    <section style={{direction:"rtl"}} className="row_products" id='Products'>
        <div className="container">
             <div className="main_title">
                <h2>المنتجات</h2>
                <div className='shape'></div>
            </div>
      {Product.length >= 1 ? Product.map((item => {
  const image = item.img
  console.log(image)
    return(
        <div className='conatiner_flex'>
              <div className="left">
                <h3>{item.arTitle}</h3>
                <p>{item.ardesc}</p>
              </div>
              <div className="right">
                 <Swiper
        spaceBetween={30}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
               {image.length >= 1 ? image.map((photo => {
    return(
                 <SwiperSlide><img src={photo.img} loading='lazy' alt='hollow metal doors'/></SwiperSlide>

    )
})) : null}
      </Swiper>
              </div>
          </div>
    )
})) : null}
        </div>
    </section>
  )
}

export default Products