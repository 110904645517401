import React from 'react'
import ImageGallery from "react-image-gallery";
const images = [
  {
    original: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496923/cxeghpcp9hgetxh3dhnw.jpg",
    thumbnail: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496923/cxeghpcp9hgetxh3dhnw.jpg",
  },
  {
    original: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496925/a8ysg3oqxn99zzrfqhem.jpg",
    thumbnail: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496925/a8ysg3oqxn99zzrfqhem.jpg",
  },
  {
    original: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496925/u87nsuemdljvv5bibpa1.jpg",
    thumbnail: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496925/u87nsuemdljvv5bibpa1.jpg",
  },
  {
    original: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496925/wlfmbj8bvwtuvpgajujh.jpg",
    thumbnail: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496925/wlfmbj8bvwtuvpgajujh.jpg",
  },
  {
    original: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496924/udw6l3uv82nsayqqtvbh.jpg",
    thumbnail: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496924/udw6l3uv82nsayqqtvbh.jpg",
  },
  {
    original: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496924/gfayzv0rrehylkgnta13.jpg",
    thumbnail: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496924/gfayzv0rrehylkgnta13.jpg",
  },
  {
    original: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496924/ynz8txc1a1xchgqbuctr.jpg",
    thumbnail: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496924/ynz8txc1a1xchgqbuctr.jpg",
  },
  {
    original: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496924/msmknhjboellhpm0nyhi.jpg",
    thumbnail: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496924/msmknhjboellhpm0nyhi.jpg",
  },
  {
    original: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496924/kjrunayfveccocabgsjq.jpg",
    thumbnail: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496924/kjrunayfveccocabgsjq.jpg",
  },
  {
    original: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496924/hj6tewxkkilmrofl9umh.jpg",
    thumbnail: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496924/hj6tewxkkilmrofl9umh.jpg",
  },
  {
    original: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496924/fyzza3cgfs5n2kkouzma.jpg",
    thumbnail: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496924/fyzza3cgfs5n2kkouzma.jpg",
  },
  {
    original: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496923/hhei9yumste7xdyfxzqt.jpg",
    thumbnail: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496923/hhei9yumste7xdyfxzqt.jpg",
  },
];
const Events = () => {
  return (
    <div className="eventsAr">
        <div className="container">
            <div className="main_title">
                <h2>الفاعليات</h2>
                <div className='shape'></div>
            </div>
            <div className="main-content">
             <video autoPlay muted loop>
  <source src="https://res.cloudinary.com/defcamc5x/video/upload/v1717493958/havxu54rlmtz0mb8bzzj.mp4" type="video/mp4" />
</video>

            </div>
            <div className="main_title">
                <h2>معرض <span>Windorex2024</span></h2>
                <div className='shape'></div>
            </div>
               <div className="TextEvent">
                <h3>في إطار اهتمامنا المستمر بالابتكار وتقديم الجودة العالية...</h3>
    <p>شاركت شركة سوليد للتوكيلات التجارية في فعاليات معرض Windoorex 2024، الذي أُقيم في الفترة من 17 إلى 20 مايو 2024 بمركز المؤتمرات والمعارض بالقاهرة. وقد استقطبت الشركة انتباه الزوار والمشاركين بتصاميمها المبتكرة والمميزة للأبواب المصفحة.</p>
    <p>تميزت جناحنا في المعرض بعرض مجموعة متنوعة من الأبواب المصفحة ذات الخامات العالية الجودة والتي تتميز بخصائص فريدة من نوعها، مثل عزل الصوت بفضل التقنيات المتطورة التي نستخدمها في عمليات التصنيع. كما قدّمنا تصاميم متنوعة ومبتكرة تناسب مختلف الأذواق والاحتياجات، مما جعلنا محط اهتمام الزوار الباحثين عن الحلول العصرية والأمان.</p>
    <p>وأكد المشاركون في المعرض على تفوق الشركة في قطاع الأبواب المصفحة، حيث حظى جناحنا بإقبال كبير من قبل الزوار الذين أثنوا على جودة المنتجات واعتمادها على أحدث التقنيات في مجال الأمان والعزل.</p>
    <p>وفي ختام المعرض، أعربت إدارة الشركة عن شكرها وتقديرها لكل الزوار الذين زاروا جناحها وأظهروا اهتمامهم بمنتجاتنا، مع التأكيد على استمرارها في تقديم حلول مبتكرة وجودة عالية تلبي احتياجات العملاء في مجال الأبواب المصفحة.</p>

               </div>
            <div className="img-album">
                <div className="flex-img">
                    <ImageGallery items={images} lazyLoad="true" slideInterval="5000" autoPlay="true" showNav="false" showFullscreenButton="false"/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Events