import React from 'react'
import ImageGallery from "react-image-gallery";
const images = [
  {
    original: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496923/cxeghpcp9hgetxh3dhnw.jpg",
    thumbnail: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496923/cxeghpcp9hgetxh3dhnw.jpg",
  },
  {
    original: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496925/a8ysg3oqxn99zzrfqhem.jpg",
    thumbnail: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496925/a8ysg3oqxn99zzrfqhem.jpg",
  },
  {
    original: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496925/u87nsuemdljvv5bibpa1.jpg",
    thumbnail: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496925/u87nsuemdljvv5bibpa1.jpg",
  },
  {
    original: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496925/wlfmbj8bvwtuvpgajujh.jpg",
    thumbnail: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496925/wlfmbj8bvwtuvpgajujh.jpg",
  },
  {
    original: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496924/udw6l3uv82nsayqqtvbh.jpg",
    thumbnail: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496924/udw6l3uv82nsayqqtvbh.jpg",
  },
  {
    original: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496924/gfayzv0rrehylkgnta13.jpg",
    thumbnail: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496924/gfayzv0rrehylkgnta13.jpg",
  },
  {
    original: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496924/ynz8txc1a1xchgqbuctr.jpg",
    thumbnail: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496924/ynz8txc1a1xchgqbuctr.jpg",
  },
  {
    original: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496924/msmknhjboellhpm0nyhi.jpg",
    thumbnail: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496924/msmknhjboellhpm0nyhi.jpg",
  },
  {
    original: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496924/kjrunayfveccocabgsjq.jpg",
    thumbnail: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496924/kjrunayfveccocabgsjq.jpg",
  },
  {
    original: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496924/hj6tewxkkilmrofl9umh.jpg",
    thumbnail: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496924/hj6tewxkkilmrofl9umh.jpg",
  },
  {
    original: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496924/fyzza3cgfs5n2kkouzma.jpg",
    thumbnail: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496924/fyzza3cgfs5n2kkouzma.jpg",
  },
  {
    original: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496923/hhei9yumste7xdyfxzqt.jpg",
    thumbnail: "https://res.cloudinary.com/defcamc5x/image/upload/v1717496923/hhei9yumste7xdyfxzqt.jpg",
  },
];
const Events = () => {
  return (
    <div className="events">
        <div className="container">
            <div className="main_title">
                <h2>Events</h2>
                <div className='shape'></div>
            </div>
            <div className="main-content">
             <video autoPlay muted loop>
  <source src="https://res.cloudinary.com/defcamc5x/video/upload/v1717493958/havxu54rlmtz0mb8bzzj.mp4" type="video/mp4" />
</video>
            </div>
           <div className="main_title">
                <h2>Windorex2024 Exhibition</h2>
                <div className='shape'></div>
            </div>
            <div className="TextEvent">
                <h3>In line with our commitment to innovation and delivering high quality</h3>
    <p>Solid for Agencies participated in the Windoorex 2024 Exhibition, which took place from May 17th to May 20th, 2024, at the Cairo International Convention and Exhibition Center. The company attracted the attention of visitors and participants with its innovative and distinctive designs for armored doors.</p> <p>Our booth stood out at the exhibition, showcasing a diverse range of high-quality armored doors. These doors feature unique characteristics, such as advanced sound insulation technologies used in our manufacturing processes. We also presented various creative designs that cater to different tastes and needs, making us a focal point for visitors seeking modern and secure solutions.</p> <p>Participants at the exhibition praised the excellence of the our company in the armored doors sector. Our booth received significant interest from visitors who commended the product quality and our commitment to using the latest technologies in safety and insulation.</p> <p>In conclusion, the company’s management expressed gratitude and appreciation to all visitors who explored our booth and showed interest in our products. We reaffirm our dedication to providing innovative solutions and high-quality armored doors that meet our customers’ needs.</p>

               </div>
            <div className="img-album">
                <div className="flex-img">
                    <ImageGallery items={images} lazyLoad="true" slideInterval="5000" autoPlay="true" showNav="false" showFullscreenButton="false"/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Events