const Product = [
  {
    id: 1,
    title: "Fire rated doors",
    arTitle: "الابواب المقاومة للحريق",
    desc: "France Metal offers a complete range of standard hollow metal doors in face sheet gauges ranging from 18 to 14 (1.25 mm to 2 mm). France Metal has variety of core choices including honeycomb, polyurethane foam and temperature rise cores.",
    ardesc:
      "نقدم مجموعة كاملة من الأبواب المعدنية المقاومة للحريق بالعديد من المقاسات القياسية مع مجموعة متنوعة من الحشو الداخلي مثل (الصوف الصخري - البوريثان)",
    img: [
      {
        img: "https://res.cloudinary.com/defcamc5x/image/upload/v1694963770/elvw3oodppv1xwnjwont.webp",
      },
      {
        img: "https://res.cloudinary.com/defcamc5x/image/upload/v1695731342/r6yyn1voegwwfyv476jy.webp",
      },
    ],
  },
  {
    id: 2,
    title: "Saftey Doors",
    arTitle: "الأبواب المصفحه",
    desc: "Prestige is the heart of Dierre’s best doors. Every detail is produced and researched, an advantage that allows us flexibility, but also rigor in the care we pay to every detail. The combination of each safety feature offers a truly advanced line Architectural ",
    ardesc:
      "الفخامة هي اساس افضل أبواب Dierre's. إن إنتاج كل التفاصيل والبحث فيها هي ميزة تتيح لنا المرونة، ولكن أيضًا الدقة في العناية التي نضعها في كل التفاصيل. إن الجمع بين كل ميزة أمان يوفر خطًا متقدمًا حقًا. الحجم المعماري 103",
    img: [
      {
        img: "https://res.cloudinary.com/defcamc5x/image/upload/v1694963770/z17fgykurwoplj5kak3s.webp",
      },
      {
        img: "https://res.cloudinary.com/defcamc5x/image/upload/v1694963771/xkpcyau1gah4dcytfvnh.webp",
      },
      {
        img: "https://res.cloudinary.com/defcamc5x/image/upload/v1694963772/rzv2wx8m6o6hkaytwpr4.webp",
      },
    ],
  },
  {
    id: 3,
    title: "X-Ray Doors",
    arTitle: " أبواب الأشعة X-RAY",
    desc: "Achieve effective radiation protection with the France Metal full line of high quality lead lined doors and frames. These openings accomplish the important task of reliably containing radiation in sensitive areas.",
    ardesc:
      "تقدم فرانس ميتال مجموعة كاملة من الأبواب المبطنة بمادة الرصاص عالية الجودة والتي تمتص الإشعاع بشكل موثوق في المباني الحساسة مثل المستشفيات والأمكنة",
    img: [
      {
        img: "https://res.cloudinary.com/defcamc5x/image/upload/v1694963767/mooreb3r2ualh3fizdwc.webp",
      },
      {
        img: "https://res.cloudinary.com/defcamc5x/image/upload/v1695731986/sydess8jzv4ajfsajtj6.webp",
      },
    ],
  },
  {
    id: 4,
    title: "Stainless Steel Doors",
    arTitle: "أبواب استانليس ستيل",
    desc: "Stainless-Tech by France Metal gives architects and designers a sleek aesthetic option to satisfy today’s style preferences. The complete, high-quality stainless steel door and frame system is ideal for commercial, institutional, and high style environments.",
    ardesc:
      "أبواب استانليس تيل مصنوعة بتقنية الفولاذ المقاوم للصدأ ليكون اختياراً مثالياً وانيقاً للعديد من المباني التجارية والمؤسسات عالية الطراز",
    img: [
      {
        img: "https://res.cloudinary.com/defcamc5x/image/upload/v1694963771/hftzhvtw46vrltzmclyq.webp",
      },
    ],
  },
  {
    id: 5,
    title: "Acoustical Doors",
    arTitle: "أبواب عازلة الصوت",
    desc: "Using the latest technology in light weight sound absorbing techniques, France Metal has developed acoustical assemblies to be the noise solution for any facility.",
    ardesc: "تستخدم فرانس ميتال أحدث التقنيات في امتصاص الأصوات لتكون الحل الأمثل للضوضاء في أي مبنى خاصة الفنادق والمكاتب",
    img: [
      {
        img: "https://res.cloudinary.com/defcamc5x/image/upload/v1695732341/hmixtwppf2ojujavfrbx.webp",
      },
      {
        img: "https://res.cloudinary.com/defcamc5x/image/upload/v1695732231/byoj13gszr4wu9s7kc3e.webp",
      },
    ],
  },
  {
    id: 6,
    title: "Armor Shield",
    arTitle: "الابواب المقاومة للرصاص",
    desc: "Level 1 through Level 3 bullet resisting door and frame (UL 752). The Armor-Shield System provides cost-efficient readily available protection against assault and vandalism at vulnerable door openings",
    ardesc:
      "الباب والاطار مقاومان للرصاص من المسوى 1 إلى المستوى 3 حيث يوفر نظام حماية فعالة ضد الاعتداء والتخريب ومجموعة واسعة من الأسلحة النارية مصنوع من الفولاذ عيار 12",
    img: [
      {
        img: "https://res.cloudinary.com/defcamc5x/image/upload/v1694963768/mf2jm74fh2es0zzuzbij.webp",
      },
    ],
  },
];
export default Product;