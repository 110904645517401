import React from 'react'

const About = () => {
  return (
    <section style={{direction:"rtl"}} className="row_about" id='about'>
        <img src="/images/Group 21.png" className='dec' alt="#"/>
        <div className="container">
            <div className="left" data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="1500">
            <div className="main_title">
                <h2>من نحن</h2>
                <div className='shape'></div>
            </div>
                <p>
                    عندما قررنا تأسيس شركة سوليد عام 1998، كان الحلم هو إنشاء علامة <span className='boldSpan'>"أمان"</span> بكل ما تحمله الكلمة من معنى <span className='boldSpan'>أمان</span> للعائلة من السطو <span className='boldSpan'>أمان</span> للمصانع والمشاريع الكبرى من الحرائق <span className='boldSpan'>أمان</span> لغرف العمليات من الاشعاع وتحول الحلم إلى حقيقة بفضل التشكيلة الكبيرة من الأبواب التي تنتجها شركتنا من الأبواب المصفحة والأبواب المضاده للأشعة والأبواب المضادة للنار الأبواب عازلة الصوت والعديد من الأنواع الأخرى التي تخدم <span className='boldSpan'>أمان</span> مجتمعنا في أكثر من مجال ، <span className='boldSpan'>الأمان</span> هو التزامنا تجاه عملائنا.

                </p>
            </div>
            <div className="right" data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="1500">
                <img src="https://res.cloudinary.com/defcamc5x/image/upload/v1694966146/fczjiqrrihzwka7blgvh.webp" alt="#" />
            </div>
        </div>
        <img src="/images/Ellipse 8.webp" className="Ellipse" alt="#" />
    </section>
  )
}

export default About